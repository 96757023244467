<template>
	<div class="home">
		<div class="statistika">
			<div class="tstat-item">
				<div class="tstat-item-box item1">
					<div class="item-title">
						<span>بۈگۈنكى كىرىم</span>
						<icon class="icon" name="yen-sign" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item2">
					<div class="item-title">
						<span>ئومومىي كىرىم</span>
						<icon class="icon" name="yen-sign" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item3">
					<div class="item-title">
						<span>بۈگۈن قوشۇلغان ئابۇنت</span>
						<icon class="icon" name="user-plus" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item4">
					<div class="item-title">
						<span>ئومومىي ئابۇنىت</span>
						<icon class="icon" name="users" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item5">
					<div class="item-title">
						<span>بۈگۈنى زاكاز</span>
						<icon class="icon" name="th-list" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item6">
					<div class="item-title">
						<span>ئومومىي زاكاز</span>
						<icon class="icon" name="th" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item6">
					<div class="item-title">
						<span>ئومومىي زاكاز</span>
						<icon class="icon" name="th" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
			<div class="tstat-item">
				<div class="tstat-item-box item6">
					<div class="item-title">
						<span>ئومومىي زاكاز</span>
						<icon class="icon" name="th" scale="2" />
					</div>
					<div class="number">
						<span>1254</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 表格 -->
		<el-table
		    :data="people"
			align="right"
		    stripe
		    style="width: 100%;">
		    <el-table-column
		      prop="name"
		      label="سېستىما چۈشەندۈرلىشى"
		      width="180"
			  align="right">
		    </el-table-column>
		    <el-table-column
		      prop="desc"
		      label="سېستىما ئۇچۇرى"
		      align="right">
		    </el-table-column>
		  </el-table>
	</div>
</template>

<script>
	// @ is an alias to /src
	import HelloWorld from '@/components/HelloWorld.vue'

	export default {
		name: 'HomeView',
		components: {
			HelloWorld
		},
		data() {
			return {
				people: [{
						name: 'نۆۋەتتىكى نەشىرى',
						desc: '1.0'
					},
					{
						name: 'سېستىما مۇھىتى',
						desc: 'PHP 7.4'
					},
					{
						name: 'ساندان',
						desc: 'MYSQL 5.6'
					},
					{
						name: 'مۇلازىمىتىر سېستىمىسى',
						desc: 'LINUX'
					},
					{
						name: 'توربەت نامى',
						desc: 'example.com'
					},
					{
						name: 'تېخنىكا قوللىغۇچى',
						desc: 'UYKAR'
					},
					{
						name: 'تېخنىك ئۈندىدار',
						desc: 'UYKAR221'
					}
				]
			};
		}

	}
</script>
<style scoped>
	.home {
		width: 100%;
		height: 100%;
		direction: rtl;
	}

	.statistika {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-between;
	}

	.tstat-item {
		height: 200px;
		flex: 25%;
		margin-bottom: 40px;
		padding: 20px;
		box-sizing: border-box;
	}

	.tstat-item-box {
		height: 100%;
		background: green;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		box-sizing: border-box;
		color: white !important;

		.number {
			font-size: 38px;
		}
	}

	.item-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 18px;

	}

	.item-title span {
		margin-bottom: 30px;
	}

	.item1 {
		background: linear-gradient(to right, #fd6b93, #fda09f);
	}

	.item2 {
		background: linear-gradient(to right, #365fff, #6e81ff);
	}

	.item3 {
		background: linear-gradient(to right, #f3b420, #facf6f);
	}

	.item4 {
		background: linear-gradient(to right, #1cbcdd, #65cde1);
	}

	.item5 {
		background: linear-gradient(to right, #ff452c, #ff9b72);
	}

	.item6 {
		background: linear-gradient(to right, #03a55a, #00c96b);
	}
	
</style>