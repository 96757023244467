<template>
	<el-container style="height: 100%;width: 100%;">
		<el-header style="background: #fff;height: 70px;">
			<h3 style="line-height: 60px;float: left;">ئۇيكار باشقۇرۇش سۇپىسى</h3>
			<el-row style="float: right;">
				<el-col :span="4" style="margin-top: 10px;">
					
					<el-menu style="display: inline-block;height: 60px;overflow: hidden;" :default-active="activeIndex.toString()" class="el-menu-demo" mode="horizontal"
						>
						<el-submenu index="1">
							<template slot="title">我的工作台</template>
							<el-menu-item index="2-1">选项1</el-menu-item>
							<el-menu-item index="2-2">选项2</el-menu-item>
							<el-menu-item index="2-3">选项3</el-menu-item>
						</el-submenu>
					</el-menu>
				</el-col>
			</el-row>
		</el-header>
		<el-container>
			<el-aside style="width: auto imo !important;background: #fff;">
				<el-menu default-active="1-4-1" style="border: none;">
					<span @click="motclick(item,index)" v-for="(item,index) in menu">
						<router-link :to="item.url">
							<el-menu-item :index="index.toString()">
								<i :class="item.icon"></i>
								<span slot="title">{{item.name}}</span>
							</el-menu-item>
						</router-link>
					</span>
				</el-menu>
			</el-aside>
			<el-main>
				<el-breadcrumb separator="/" style="margin-bottom: 14px;">
					<el-breadcrumb-item :to="{ path: '/' }">باشبەت</el-breadcrumb-item>
					<el-breadcrumb-item>{{menuName}}</el-breadcrumb-item>
				</el-breadcrumb>
				<router-view />
			</el-main>
		</el-container>
	</el-container>
	<!-- <nav>
			<div class="slider-top" @click="alerts">ئارقا بەت باشقۇرۇش سۇپىسى</div>
			<router-link to="/">
				<icon class="icon" name="home" pulse />باشبەت
			</router-link>
			<router-link to="/banner">
				<icon class="icon" name="th-large" />ئايلانما باشقۇرۇش
			</router-link>
			<router-link to="/movie">
				<icon class="icon" name="tags" />كىنو باشقۇرۇش
			</router-link>
			<router-link to="/tags">
				<icon class="icon" name="tags" />تۈر باشقۇرۇش
			</router-link>
			<router-link to="/user">
				<icon class="icon" name="user" />ئەزا باشقۇرۇش
			</router-link>
			<router-link to="/comment">
				<icon class="icon" name="comment" />ئىنكاس باشقۇرۇش
			</router-link>
			<router-link to="/about">
				<icon class="icon" name="user-tie" />ۋاكالەتچى باشقۇرۇش
			</router-link>
			<router-link to="/vip">
				<icon class="icon" name="server" />VIP باشقۇرۇش
			</router-link>
			<router-link to="/dollar">
				<icon class="icon" name="yen-sign" /> مالىيە باشقۇرۇش
			</router-link>
			<router-link to="/system">
				<icon class="icon" name="wrench" />سېستىما تەڭشەش
			</router-link>
		</nav>
		<div class="content">
			<div class="header">
				بەت ئۈستى
			</div>
			<div class="content-col">
				<div class="content-box">
					<router-view />
				</div>
			</div>
		</div> -->
</template>
<script>
	export default {
		name: 'App',
		data() {
			return {
				isCollapse: false,
				menuName: sessionStorage.getItem('menu') || '',
				activeIndex:0,
				menu: [

					{
						name: 'باشبەت',
						icon: 'el-icon-s-home',
						url: '/'
					},
					{
						name: 'ئايلانما باشقۇرۇش',
						icon: 'el-icon-picture',
						url: '/banner?id=0'
					},
					{
						name: 'كىنو باشقۇرۇش',
						icon: 'el-icon-video-camera-solid',
						url: '/movie'
					},
					{
						name: 'تۈر باشقۇرۇش',
						icon: 'el-icon-s-grid',
						url: '/tags'
					},
					{
						name: 'ئەزا باشقۇرۇش',
						icon: 'el-icon-s-custom',
						url: '/user'
					},
					{
						name: 'ئىنكاس باشقۇرۇش',
						icon: 'el-icon-s-comment',
						url: '/comment'
					},
					{
						name: 'ۋكالەتچى باشقۇرۇش',
						icon: 'el-icon-s-check',
						url: '/about'
					},
					{
						name: 'VIP باشقۇرۇش',
						icon: 'el-icon-s-cooperation',
						url: '/vip'
					},
					{
						name: 'زاكاز باشقۇرۇش',
						icon: 'el-icon-s-order',
						url: '/dollar'
					},
					{
						name: 'سېستىما تەڭشەش',
						icon: 'el-icon-setting',
						url: '/system'
					}
				]
			};
		},
		onLaunch: function(e) {
			console.log(process.env.BASE_URL,'8888888888888888')
			const preferredLanguage = uni.getStorageSync('language');
			if (preferredLanguage) {
				this.$i18n.locale = preferredLanguage;
			}

			document.getElementsByTagName('head')[0].appendChild(meta);
		},
		onload(e) {
			console.log(e)
		},
		onShow: function() {

		},
		onHide: function() {

		},
		methods: {
			alerts() {
				console.log(0)
			},
			motclick(e, index) {
				this.menuName = e.name
				sessionStorage.setItem('menu', e.name);
			}
		},
	}
</script>
<style lang="scss">
	@import url('../static/font.css');

	html,
	body {
		height: 100%;
		width: 100%;
		min-width: 999px;
		overflow: hidden;
		background: #eef1f6;
	}

	.rtl {
		direction: rtl;
	}

	.ltr {
		direction: ltr;
	}

	p {
		margin: 10px 0;
	}

	a,
	.el-tag {
		cursor: pointer;
	}

	.el-button+.el-button,
	.el-checkbox.is-bordered+.el-checkbox.is-bordered {
		margin-right: 10px !important;
	}

	.el-message-box__status+.el-message-box__message {
		padding-left: 12px !important;
		padding-right: 32px !important;
	}

	.el-message-box__status {
		right: 0 !important
	}

	.el-form-item__content {
		margin-right: 120px !important;
	}

	.el-form-item__label {
		float: right !important;
		padding-left: 12px !important;
	}

	.el-radio__label {
		padding-left: 0 !important;
		padding-right: 10px;
	}

	* {
		font-family: 'UKIJTuzTom';
		padding: 0;
		margin: 0;
		text-decoration: none;
	}

	// ::-webkit-scrollbar{
	// 	display: none !important;
	// 	width: 0 !important;
	// }
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		background: #f6f9fa;
		color: #2c3e50;
	}

	.fa-icon {
		fill: currentColor;
	}

	.slider-col {
		// display: flex;
		height: 100%;
		width: 100%;
	}

	.content {
		flex: 1;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	.header {
		width: 100%;
		height: 50px;
		background: white;
	}

	.content-col {
		padding: 20px;
		box-sizing: border-box;
		flex: 1;
		width: 100%;
	}

	.content-box {
		height: 100%;
		width: 100%;
		background: white;
		padding: 15px;
		box-sizing: border-box;
	}

	.slider-top {
		height: 50px;
		line-height: 50px;
	}

	nav {
		width: 220px;
		background: #ffffff;
		position: relative;
		z-index: 0;

		.icon {
			margin-right: 8px;

		}

		a {
			font-weight: bold;
			color: #2c3e50;
			display: block;
			width: 100%;
			height: 50px;
			line-height: 50px;
			text-align: left;
			padding: 0 30px;
			box-sizing: border-box;
			white-space: nowrap;

			&.router-link-exact-active {
				color: #42b983;
				background: #e0eff9;

				icon {
					color: #42b983;
					;
				}
			}
		}
	}
</style>