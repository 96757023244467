import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { sendRequest } from './common/request.js';
import { baseUrl } from './common/request.js';
import { uploadImage } from './common/request.js';
import { time } from './common/core.js'
Vue.prototype.$Request = sendRequest;
Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$image = uploadImage;
Vue.prototype.$time = time();
Vue.use(ElementUI);
Vue.component('icon', Icon)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')