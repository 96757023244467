import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
	  path:'/banner',
	  name:'banner',
	  component: () => import('../views/BannerView.vue')
  },
  {
  	  path:'/comment',
  	  name:'comment',
  	  component: () => import('../views/CommentView.vue')
  },
  {
  	  path:'/dollar',
  	  name:'dollar',
  	  component: () => import('../views/DollarView.vue')
  },
  {
  	  path:'/system',
  	  name:'system',
  	  component: () => import('../views/SystemView.vue')
  },
  {
  	  path:'/tags',
  	  name:'tags',
  	  component: () => import('../views/TagsView.vue')
  },
  {
  	  path:'/user',
  	  name:'user',
  	  component: () => import('../views/UserView.vue')
  },
  {
  	  path:'/vip',
  	  name:'vip',
  	  component: () => import('../views/VipView.vue')
  },
  {
  	  path:'/movie',
  	  name:'movie',
  	  component: () => import('../views/MovieView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
