export const baseUrl = 'https://daris.kaxtancn.cn/api/';
//'https://daris.kaxtancn.cn/api/';
export function sendRequest(
	domainSuffix,
	data,
	method = 'POST',
	headers = {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
	}) {
	const url = baseUrl + domainSuffix;
	const requestOptions = {
		method: method,
		headers: headers,
		body: new URLSearchParams(data).toString()
	};

	return new Promise((resolve, reject) => {
		fetch(url, requestOptions)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(data => {
				resolve(data);
			})
			.catch(error => {
				reject(error);
			});
	});
}
/**
 * @param {Object} data
 * 上传图片
 */
export async function uploadImage(data) {
	try {
		const response = await fetch(baseUrl+'upload/image', {
			method: 'POST',
			body: data 
		});

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}
		const responseData = await response.json();
		return responseData;
	} catch (error) {
		throw error;
	}
}